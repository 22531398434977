/* 背景框样式 */
.change-email-password-container {
    width: 400px; /* 设定背景框宽度 */
    margin: 0 auto; /* 居中 */
    padding: 20px; /* 内边距 */
    background-color: #f0f0f0; /* 背景色 */
    border-radius: 10px; /* 圆角 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 阴影 */
  }
  
  /* 输入框组样式 */
  .change-email-password-container .input-group {
    margin-bottom: 20px; /* 增加下边距 */
    width: 80%; /* 让输入框组铺满父容器 */
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: column; /* 垂直方向排列子元素 */
  }
  
  /* 输入框样式 */
  .change-email-password-container input {
    width: 90%; /* 让输入框等宽 */
    margin-bottom: 10px; /* 增加下边距 */
    padding: 10px; /* 增加内边距 */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* 输入框样式 */
  .change-email-password-container input {
    width: 100%;
    margin-bottom: 10px; /* 增加下边距 */
    padding: 10px; /* 增加内边距 */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* 错误消息样式 */
  .change-email-password-container .error {
    margin-top: 10px; /* 调整错误消息的位置 */
    color: red;
  }
  
  /* 成功消息样式 */
  .change-email-password-container .success {
    margin-top: 10px; /* 调整成功消息的位置 */
    color: green;
  }
  
  /* 按钮样式 */
  .change-email-password-container button {
    margin-top: 20px; /* 增加上边距 */
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* 鼠标悬停按钮样式 */
  .change-email-password-container button:hover {
    background-color: #0056b3;
  }
  