.register-container {

    padding: 20px;
}

.login-table {

    border-collapse: collapse;
    width: 100%;
    border: 2px solid black;
    border-radius: 10px; /* 添加整个表格的圆角边框 */
    overflow: hidden; /* 防止圆角被内容溢出破坏 */
}

.login-table th,
.login-table td {

    padding: 10px;
    text-align: left;
    border: 1px solid black; /* 单元格边框 */
}

.login-table th {

    background-color: #0a98c4; /* 表头的背景颜色 */
}

.login-table td {

    background-color: #ffffff; /* 表格单元格背景颜色 */
}

.register-popup {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #ffffff;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.register-form {
    
    position: relative;
}

.close-button {

    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.register-form h2 {

    margin-bottom: 10px;
}

.register-form label {

    margin-bottom: 5px;
    display: block;
}

.register-form input {

    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
}

.register-form .error-message {

    color: #dc3545;
    margin-bottom: 10px;
}

.register-form button {

    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-button,
.edit-button {

    padding: 8px 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    transition: background-color 0.3s ease;
}

.register-button:hover,
.edit-button:hover {

    background-color: #004085;
}

.register-container {

    display: flex;
    flex-direction: column;
    align-items: center;
}

.register-button {

    margin-top: 20px;
}

.confirmation-popup {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8); /* 半透明黑色背景 */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation-content {

    background-color: #fff; /* 白色背景 */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.confirmation-content h2 {

    margin-bottom: 10px;
}

.confirmation-content p {
    
    margin-bottom: 20px;
}

.button-group {

    display: flex;
    justify-content: center;
}

.confirm-button,
.cancel-button {

    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-button {

    background-color: #dc3545; /* 红色背景 */
    color: #fff; /* 白色文字 */
}

.confirm-button:hover {
    
    background-color: #c82333; /* 深红色背景 */
}

.cancel-button {

    background-color: #007bff; /* 蓝色背景 */
    color: #fff; /* 白色文字 */
}

.cancel-button:hover {

    background-color: #0056b3; /* 深蓝色背景 */
}