/* Contact button itself */
.contact-button {

    position: relative;
}

.contact-button > button {

    height: 100%;
}

/* Dropdown content of button */
.contact-content {

    position: absolute;
    padding: 8px 18px 18px 20px;
    width: 400px;
    left: -234%;
    border-radius: 5px;
    background-color: var(--theme-primary-4);
    display: none;
    z-index: 2;
}

.contact-content-wrapper {

    width: 100%;
}

.contact-content-wrapper > h2 {

    margin: 0 0 6px 0;
}

.contact-content.active {

    display: block;
}
