.your-component-container {

    padding: 20px;
    width: calc(100%); 
}

.customer-display,
.crew-members-display {

    margin-top: 10px;
    padding: 5px;
    width: calc(100% - 22px); /* 让搜索框与菜单栏等宽 */
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.your-component-container,
.customer-display,
.crew-members-display,
.menu-options div {

    color: #000; /* 将字体颜色设置为黑色 */
}

.customer-display,
.crew-members-display {

    min-height: 100px;
    overflow-y: auto;
}

.content-container {

    background-color: #fff; /* 设置容器背景色为白色 */
    margin-top: 10px; /* 下移容器显示框 */
}

.customer-container {

    font-family: "Raleway", Sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.customer-buttons button {

    font-family: "Raleway", sans-serif; /* Added font-family */
    margin: 5px;
    padding: 10px 20px;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.customer-buttons button:hover {

    background-color: #ccc;
    color: #fff;
}

/* 悬浮时不改变字体颜色 */
.customer-buttons button:hover {

    color: #000;
}

/* 按下时的样式 */
.customer-buttons button:active {

    background-color: #007bff;
    color: #fff;
}

.pagination-container {

    margin-top: 20px;
}

.customer-buttons button {

    font-family: "Raleway", sans-serif; /* Added font-family */
    margin: 5px;
    padding: 10px 20px;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-container button {

    font-family: "Raleway", sans-serif; /* Added font-family */
    margin: 0 5px;
    padding: 10px 20px;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-container button:hover {

    background-color: #ccc;
    color: #fff;
}

.menu-bar {

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--theme-primary-4to5); /* 设置背景色为浅蓝色 */
    padding: 10px;
    border-radius: 10px; /* 添加圆角 */
    margin-top: -15px;
}

.menu-item {

    font-family: "Rum Raisin", Sans-serif;
    font-size: 20px; /* Adjust the font size to your preference */
    position: relative;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}


.menu-toggle {

    cursor: pointer;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    vertical-align: middle;
    margin-bottom: -5px;
    background-color: #fff; /* 背景色为白色 */
}

.menu-options {

    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: none;
}

.menu-item:hover .menu-options {

    display: block;
}

.menu-options div {

    padding: 5px 0px;
    cursor: pointer;
    text-align: center;
}

/* Apply box shadow and darken background color on hover */
.menu-options div:hover {

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.95);
}

.current-date {

    font-weight: bold;
    vertical-align: middle;
}

/* Summary and Email CSS */
.summary-container {

    margin-bottom: 20px;
}

.summary-container h4 {

    font-size: 18px;
    margin-bottom: 10px;
}

.summary-container table {

    width: 100%;
    border-collapse: collapse;
}

.summary-container th,
.summary-container td {

    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

/* Apply background color to table header */
.summary-container th {

    background-color: var(--theme-primary-4to5);
    color: rgb(0, 0, 0); /* Optionally set text color to white */
}

.email-container {

    margin-top: 20px;
}

.email-container h4 {

    font-size: 18px;
    margin-bottom: 10px;
}

.email-container textarea {

    width: 95%;
    min-height: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

.toast {

    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    z-index: 999;
    transition: opacity 0.3s ease-in-out;
}

.toast.success {

    background-color: green;
}

.toast.error {

    background-color: red;
    color: white;
}

.submit-button-container {

    text-align: center;
}

.submit-button-container button {

    padding: 10px 50px;
    font-size: 16px;
    background-color: #007bff; /* 默认颜色为蓝色 */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button-container button:hover {
    
    background-color: #0056b3; /* 悬停颜色变深 */
}

.submit-button-container button:active {

    background-color: #003f7f; /* 点击颜色再次变深 */
}