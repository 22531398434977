.navigation-bar {

    width: 100%;
    margin-top: 10px;
}

.navigation-bar-content {

    padding: 0;
    margin: 0;
    list-style-type: none;
}

.navigation-bar-item {

    display: inline;
    margin: 0 8px;
    color: var(--theme-constant-rev);
    position: relative;
    cursor: pointer;
}

.navigation-bar-item.active::after {

    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--theme-primary-4);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);

}