.sunrise-sunset-container {

    background-color: var(--theme-primary-5);
    margin-bottom: 2%;
}

.sunrise-sunset-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 8px;
}

.sunrise-sunset-header {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sunrise-sunset-header-divider {

    background-color: var(--theme-primary-4);
    border: 2px 2px 2px 2px;
    border-radius: 2px;
    padding: 2px;
}

.ss-location,
.ss-date {

    margin: 0;
    color: var(--theme-gray-2);
    font-size: 1.2em;
    text-align: center;
    max-width: 120px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    overflow-wrap: break-word;
}

.ss-location {

    margin-right: 15px;
}

.ss-date {

    margin-left: 15px;
}

.sunrise-sunset-main-content {

    width: 55%;
    min-width: 175px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ss-time {

    display: flex;
    flex-direction: column;
    align-items: center;
}

.ss-time>p {

    margin: 5px 0 0 0;
}

.ss-time>h2 {

    margin: 15px 0 0 0;
    font-size: 1.2em;
}

.ss-time>h2:after {

    content: '';
    display: block;
    width: 95%;
    margin: auto;
    background: var(--theme-primary-4to5);
    height: 2px;
}

.loading-message {

    font-style: italic;
}

.sunrise-sunset-info .sunrise-time,
.sunrise-sunset-info .sunset-time {
    
    color: rgb(0, 0, 0);
    font-size: 20px;
}

.searchbar-container:after {

    content: '';
    display: block;
    width: 95%;
    margin: auto;
    background: var(--theme-primary-4to5);
    height: 2px;
}