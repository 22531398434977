/* 登出按钮样式 */
.logout-button {

    width: 150px; /* 按钮宽度 */
    margin-top: auto; /* 自动计算距离上方空白的距离 */
}
  
/* 悬停效果 */
.menu-button:hover,
.sub-menu-button:hover,
.calendar-button:hover,
.logout-button:hover {

    background-color: #007bff;
    color: #fff;
}

/* 按钮按下效果 */
.calendar-button.pressed,
.logout-button.pressed {

    background-color: #0056b3;
}

/* 登出按键 */
.logout-button {

    padding: 10px; /* 与其他按钮相同的内边距 */
    border: none; /* 与其他按钮相同的边框 */
    border-radius: 5px; /* 与其他按钮相同的圆角 */
    font-size: 16px; /* 与其他按钮相同的字体大小 */
    font-family: Helvetica, sans-serif; /* 选择一个更圆润的字体 */
    background-color: #000000; /* 与其他按钮相同的背景颜色 */
    color: #ffd900; /* 与其他按钮相同的文字颜色 */
    cursor: pointer; /* 设置鼠标指针为手型 */
    transition: background-color 0.3s ease, color 0.3s ease; /* 设置过渡效果 */
    text-align: center; /* 文字居中 */
}

.logout-button:hover {

    background-color: #ffd900; /* 悬停时背景颜色 */
    color: #000000; /* 悬停时文字颜色 */
}

.logon-button {

    padding: 10px; /* 与其他按钮相同的内边距 */
    border: none; /* 与其他按钮相同的边框 */
    border-radius: 5px; /* 与其他按钮相同的圆角 */
    font-size: 16px; /* 与其他按钮相同的字体大小 */
    font-family: Helvetica, sans-serif; /* 选择一个更圆润的字体 */
    background-color: #000000; /* 与其他按钮相同的背景颜色 */
    color: #ffd900; /* 与其他按钮相同的文字颜色 */
    cursor: pointer; /* 设置鼠标指针为手型 */
    transition: background-color 0.3s ease, color 0.3s ease; /* 设置过渡效果 */
    text-align: center; /* 文字居中 */
}

.logon-button:hover {

    background-color: #ffd900; /* 悬停时背景颜色 */
    color: #000000; /* 悬停时文字颜色 */
}

/* 将折叠动画应用于菜单栏 */
.sidebar {

    width: 200px;
    height: 100vh; /* 设置菜单栏的高度与视口高度相同 */
    background-color: var(--theme-primary-5);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 0px solid #dee2e600;
    position: fixed;
    left: 0; /* 菜单栏在左侧 */
    top: 0; /* 菜单栏在顶部 */
    z-index: 9999;
}

/* 定义折叠和展开动画 */
@keyframes menu-collapse-animation {

    from {
        width: 200px; /* 展开状态宽度 */
    }
    to {
        width: 30px; /* 折叠状态宽度 */
    }
}

@keyframes menu-expand-animation {

    from {
        width: 30px; /* 折叠状态宽度 */
    }
    to {
        width: 200px; /* 展开状态宽度 */
    }
}

/* 当菜单折叠时，应用折叠动画类 */
.sidebar.collapsed {

    animation: menu-collapse-animation 0.5s ease forwards; /* 应用折叠动画 */
}

/* 当菜单展开时，应用展开动画类 */
.sidebar.expanded {

    animation: menu-expand-animation 0.5s ease forwards; /* 应用展开动画 */
}

/* 设置按钮内容的布局 */
.button-content {

    display: flex;
    align-items: center;
}

/* 设置图片的样式 */
.button-icon {

    position: absolute; /* 设置绝对定位 */
    left: 5px; /* 将图片固定在按钮的左侧 */
}

/* 将折叠后的按钮位置放置在菜单栏中间 */
.menu-button {

    width: 100%; /* 按钮宽度占满父元素 */
    height: 40px; /* 按钮高度 */
    display: flex;
    align-items: center;
    justify-content: center; /* 将内容居中 */
    margin-bottom: 10px; /* 底部外边距 */
    background-color: #ffffff; /* 背景色 */
    border: 0px solid #cccccc00; /* 边框 */
    border-radius: 10px; /* 边角弧度 */
    cursor: pointer; /* 设置光标为手型 */
    position: relative; /* 相对定位 */
    font-family: Arial, sans-serif; /* 设置字体 */
    font-size: 16px; /* 设置字体大小 */
    font-weight: bold; /* 加粗字体 */
    transition: width 0.6s ease; /* 添加宽度过渡效果 */
}

/* 设置按钮文字样式 */
.menu-button span {

    margin-left: 10px; /* 文字与图片之间的间距 */
}

/* 标记页面按键 */
.menu-button.active,
.expanded-menu-button.active {

    background-color: #c20808; /* 你想要的背景颜色 */
}
/* 按钮悬停效果 */
.expanded-menu-button:hover {

    background-color: rgb(0, 118, 187); /* 悬停时半透明背景 */
    color: #fff;
}

.expanded-menu-button{

    width: 100%; /* 按钮宽度占满父元素 */
    height: 40px; /* 按钮高度 */
    display: flex;
    align-items: center;
    justify-content: flex-start; /* 将内容居中 */
    margin-bottom: 10px; /* 底部外边距 */
    background-color: #ffffff; /* 背景色 */
    border: 0px solid #cccccc00; /* 边框 */
    border-radius: 10px; /* 边角弧度 */
    cursor: pointer; /* 设置光标为手型 */
    position: relative; /* 相对定位 */
    font-family: Arial, sans-serif; /* 设置字体 */
    font-size: 16px; /* 设置字体大小 */
    font-weight: bold; /* 加粗字体 */
    margin-left: auto; /* 将按钮向右移动以水平居中 */
    margin-right: auto; /* 将按钮向左移动以水平居中 */
}


/* 设置按钮内容的布局 */
.button-content {

    position: relative; /* 相对定位 */
    display: flex;
    align-items: center;
}

/* 设置图片的样式 */
.button-icon {

    position: absolute; /* 设置绝对定位 */
    left: 5px; /* 将图片固定在按钮的最左端 */
}

/* 设置按钮文字样式 */
.expanded-menu-button span {

    margin-left: 10px; /* 文字与图片之间的间距 */
}