.searchbar-container {

    background-color: var(--theme-primary-5);
}

.searchbar-content-wrapper {

    display: flex;
    align-items: center;
    padding: 8px 8px;
}

.searchbar-input {

    color: var(--theme-gray-1);
    outline: unset;
    border-width: 0px;
    width: 79%;
    background-color: transparent;
}

.searchbar-input-wrapper {

    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 2%;
    padding: 1%;
    background-color: var(--theme-constant);
    position: relative;
}

.arrow-icon {

    border-radius: 0px;
    border-width: 0px;
    height: 100%;
    width: 20%;
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.arrow {

    background-color: transparent;
    border: solid var(--theme-gray-3);
    border-width: 2px 2px 0px 0px;
    padding: 4px;
    height: 0px;
    width: 0px;
    margin: 0 0 0 6px;
    position: relative;
    rotate: 45deg;
  }
  
  .arrow:before {
    
    content: '';
    border: solid var(--theme-gray-3);
    border-width: 2px 0px 0px 0px;
    width: 20px;
    height: 0px;
    font-size: 0px;
    top: 60%;
    left: -100%;
  
    position: absolute;
    rotate: -45deg;
  }

.arrow-rev {

    rotate: 225deg;
    margin: 0 4px 0 0;
}