.accordion {

    width: 100%;
    max-width: 500px;
    position: relative;
}

/* The accordion button itself */
.button {

    font-size: 16px;
    background-color: var(--theme-primary-5);
    color: #fff;
    text-align: left;
    font-weight: 700;
    display: flex;
    width: 100%;
    padding: 8px 8px;
    cursor: pointer;
    border: none;
}

/* The front section of the accordion (text + control button) */
.title_wrapper {

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2%;
    width: 100%;
    height: 100%;
}

/* Text that is displayed inside the accordion */
.title_wrapper h2 {

    margin: 0;
    font-size: 1.2em;
}

/* The control button itself (+ / -) */
.control {

    font-size: 18px;
    color: var(--theme-gray-3);
}

/* Dropdown container of content */
.content_wrapper {

    width: 100%;
    height: 0;
    overflow: hidden;
    text-wrap: wrap;
    transition: height ease 0.4s;
    position: absolute;
    z-index: 1;
}

/* Content of accordion */
.content {

    background-color: var(--theme-constant);
    padding: 2px 10px;
}

ul.content {

    margin: 0%;
    padding: 1% 5%;
}

.content li {

    word-wrap: break-word;
}

/* Blur effect for smoother opening/closing */
.content_wrapper.blur:after {

    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    height: 40%;
    bottom: 0px;
    animation: blur-animation 1.8s ease;
    pointer-events: none;
}

@keyframes blur-animation {

    from {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--theme-constant) 100%);
    }

    50% {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, var(--theme-constant) 100%);
    }

    80% {
        background: none;
    }
}